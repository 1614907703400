import React from 'react'
import Dashboard from './components/home'
import { BrowserRouter as Router , Route } from 'react-router-dom'
import About from './components/about';
import Service from './components/services/service'
import 'antd/dist/antd.css'
import Privacy from '../src/components/privacy'
import ForgotPassword from '../src/components/Login/forgotPassword'
import BankerForgotPassword from '../src/components/Login/bankerForgotPassword'
import UserAppointmentDash from '../src/components/userAppointmentList/userAppointmentDash'
import UserMessage from '../src/components/userAppointmentList/userMessage'

function App() {
  return (
    

    <Router>
      <Route path="/forgot-password-banker" component={BankerForgotPassword}></Route>

      <Route path="/forgot-password" component={ForgotPassword}></Route>
    
      <Route path="/privacy-policy-and-disclaimer" component={Privacy}></Route>

      <Route path="/messages" component={UserMessage}></Route>

      <Route path="/appointments" component={UserAppointmentDash}></Route>
          
      <Route path="/about" component={About}></Route>

      <Route path="/services" component={Service}></Route>

      <Route exact path="/" component={Dashboard}></Route>
      
    </Router>
  )
  
};

export default App;
