import '../App.css'
import main_logo from '../img/main_logo.png';
import SideMenu from '../components/side-menu'
import '../components/slider.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFirefox,
  } from "@fortawesome/free-brands-svg-icons";

  import {
    faAddressBook,
  }
from "@fortawesome/free-regular-svg-icons";
import {Link} from 'react-router-dom';
import LoginList from './Login/loginList'
import RegisterList from '../components/Login/registerlist'
import Serve from '../components/service'
import { MailFilled, PhoneFilled } from '@ant-design/icons';
<link rel="stylesheet" href="path/to/font-awesome/css/font-awesome.min.css"></link>



  const Header = () =>{


    const setToken = localStorage.getItem('UserAuth')

    const removeTokenOnLogOut =() =>{
    localStorage.removeItem('UserAuth')
    localStorage.removeItem('email')
}


    return(
           <header class="navbar-main"> 
                <div class="beforeHeader">            
                    <ul class='ul-class-design'>

                            <a href="#" className="facebook social">
                             
                            </a>
                        
                            <a href="#" className="facebook social">
                            <PhoneFilled /> 047056222
                            </a>
                            <a href="#" className="facebook social">
                            <MailFilled />   info@efb.ae
                            </a>
                    </ul>
                </div>
                <section class="header"> 
                <div class="logoHome">
                <SideMenu/>
                            <Link to ="/" class="logo-click-link-css" >
                                <img class="img" src = {main_logo} alt="LOGO" ></img>
                            </Link>
                        <ul class="ultabbar">
                            <Link to ="/" class="HomeBar">
                                    Home
                            </Link >
                            <Link to ="/about" class="HomeBar" >
                                    Why EFB
                            </Link>
                            <Link class="HomeBar"><Serve/></Link>

                            {setToken === null?
                            <div class="HomeBar" >
                            <LoginList/>
                            </div>:
                            null}

                            <div class="HomeBar" >
                                <RegisterList/>
                            </div>

                            {setToken !== null?

                            <Link to ="/appointments" class="HomeBar">
                                    Appointments
                            </Link >
                            : null}

                            {setToken !== null?
                            <Link class="HomeBar logout" onClick={removeTokenOnLogOut}>
                            Logout
                            </Link >: null}
                            
                        </ul>
                        
                        </div>            
                </section>
    </header> 
              
    )
  }

  export default Header;

